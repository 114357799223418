import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

const AddThemesForm = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    name: "",
    backgroundimage: null,
    image: null
  });
  
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [backimageUrl, setBackImageUrl] = useState(null);


  const [backgroundImagePreview, setBackgroundImagePreview] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchThemeData(id);
    }
  }, [id]);

  const fetchThemeData = async (themeId) => {
    try {
      const response = await axios.get(`${apiUrl}/getThemesById/${themeId}`);
      const themeData = response.data;
      setFormData({
        name: themeData.name,
        backgroundimage: themeData.backgroundimage,
        image: themeData.image
      });
      setImagePreview(themeData.image);
      setImageUrl(themeData.image);
      setBackImageUrl(themeData.backgroundimage);

      setBackgroundImagePreview(themeData.backgroundimage); // Set background image preview
    } catch (error) {
      console.error("Failed to fetch theme data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let uploadedImageUrl = null;
    let uploadedbackimageUrl = null;
  
    // Upload image if it's selected
    if (formData.image) {
      try {
        const file = formData.image;
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;
  
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });
  
        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };
  
        const data = await s3.upload(params).promise();
        uploadedImageUrl = data.Location;
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }
  
    // Upload background image if it's selected
    if (formData.backgroundimage) {
      try {
        const file = formData.backgroundimage;
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;
  
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });
  
        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };
  
        const data = await s3.upload(params).promise();
        uploadedbackimageUrl = data.Location;
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload background image", { autoClose: 2000 });
        return;
      }
    }
  
    try {
      const dataToSend = {
        name: formData.name,
        image: uploadedImageUrl, // Use uploadedImageUrl
        backgroundimage: uploadedbackimageUrl, // Use uploadedbackimageUrl
      };
  
      if (id) {
        await axios.put(`${apiUrl}/updateThemes/${id}`, dataToSend);
        toast.success("Theme Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/addThemes`, dataToSend);
        toast.success("Theme Added Successfully", {
          autoClose: 2000,
        });
      }
  
      setFormData({
        name: "",
        image: null,
        backgroundimage: null
      });
      setImagePreview(null);
      setBackgroundImagePreview(null);
  
      setTimeout(() => {
        navigate('/kitty-theme');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit theme data:", error);
    }
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    const file = files[0];

    if (id === "image") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    } else if (id === "backgroundimage") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        backgroundimage: file,
      }));
      setBackgroundImagePreview(URL.createObjectURL(file));
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClose = () => {
    navigate('/kitty-theme');
  };

  const handleImageRemove = (type) => {
    if (type === "image") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: null,
      }));
      setImagePreview(null);
    } else if (type === "backgroundimage") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        backgroundimage: null,
      }));
      setBackgroundImagePreview(null);
    }
  };

  return (
    <div className="container mt-4">
    <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title mb-0">{id ? "Update Theme" : "Add Theme"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">Theme Name</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="name"
                placeholder="Theme name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            {/* Image Upload */}
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="image" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Image</label>
              <input
                type="file"
                accept=".jpg,.jpeg"
                className="form-control"
                id="image"
                onChange={handleFileChange}
              />
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                  <FaTimes
                    className="image-remove-icon"
                    onClick={() => handleImageRemove("image")}
                  />
                </div>
              )}
            </div>

            {/* Background Image Upload */}
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="backgroundimage" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Background Image</label>
              <input
                type="file"
                className="form-control"
                id="backgroundimage"
                onChange={handleFileChange}
              />
              {backgroundImagePreview && (
                <div className="mb-3 col-md-12 p-2">
                  <img
                    src={backgroundImagePreview}
                    alt="Background Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                  <FaTimes
                    className="image-remove-icon"
                    onClick={() => handleImageRemove("backgroundimage")}
                  />
                </div>
              )}
            </div>

            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      </div>
    </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddThemesForm;
