import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';


const UserInformation = ({ show, handleClose, user}) => {
  console.log(user,'ysdysdsddsds');
  

  
  const [GroupkittyCount, setGroupkittyCount] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
 

  useEffect(() => {
    const getCountOfKittyAndGroupByme = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getCountOfKittyAndGroupByme/${user?._id}`);
        setGroupkittyCount(response?.data);
      } catch (error) {
        console.log("Error fetching groups data", error);
      }
    };

    if (user) {
      getCountOfKittyAndGroupByme();
    }
  }, [apiUrl, user]);






  // // Filter groups where the userId matches the current user
  // const userGroups = groups.filter(group => group?.userId?._id === user?._id);
  // console.log(userGroups, 'fdafds');

  // console.log(groups.map(group => group.userId), 'User IDs in groups');

  //   // Filter groups where the user is present in userIds array
  // const attendedGroups = groups.filter(group => 
  //   group.userIds.some(member => member.userId === user?._id)
  // );
  // console.log(attendedGroups, 'Groups attended by the user');

  // const userKittys = kittys.filter(kitty =>
  //   kitty.groupId.some(group => group.userId?._id === user?._id)
  // );
  
  // console.log(userKittys, 'Kitties created by the user');

  

  

  return (
    <Modal show={show} onHide={handleClose} className='modal-lg modal-dialog-centered'>
    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-7xl mx-auto">
      <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
        <Modal.Title className="text-xl font-semibold">User Information</Modal.Title>
        <Button variant="close" onClick={handleClose} className="text-white hover:text-gray-400">
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body className="p-6">
        {user ? (
          <div className="flex flex-wrap space-y-2">
            <div className="w-1/2">
              <p className="text-gray-700 mb-[10px]"><strong>Name:</strong> <span className="text-gray-500 font-medium capitalize">{user.fullname}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>Date of Birth:</strong> <span className="text-gray-500">{user.dob ? new Date(user.dob).toISOString().split('T')[0] : 'N/A'}</span></p>
            
              <p className="text-gray-700 mb-[10px]"><strong>Profession:</strong> <span className="text-gray-500">{user.profession}</span></p>
            </div>
            <div className="w-1/2 mt-0">
              <p className="text-gray-700 mb-[10px]"><strong>Email:</strong> <span className="text-gray-500">{user.email}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>Location:</strong> <span className="text-gray-500">{user.location}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>Phone Number:</strong> <span className="text-gray-500">{user.phoneNumber}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>Emergency Number:</strong> <span className="text-gray-500">{user.emergencyNumber}</span></p>
             
            </div>
            <div className="w-1/2">
              <p className="text-gray-700 mb-[10px]"><strong>Types of Events:</strong> <span className="text-gray-500">{user.typeofevents}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>About me:</strong> <span className="text-gray-500 capitalize">{user.about}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>Verified By:</strong> <span className="text-gray-500">{user.verifiedBy}</span></p>
              <p className="text-gray-700 mb-[10px]"><strong>Status:</strong> <span className={`${user.isActive ? 'text-green-500' : 'text-red-500'}`}>{user.isActive ? 'Active' : 'Inactive'}</span></p>
            </div>
            <div className="w-1/2">
              <strong className="text-gray-700">Group Created By Me:</strong>
              {GroupkittyCount?.groupCount ? (
                  <p  className="text-gray-500 mt-1">{GroupkittyCount?.groupCount}</p>
                
              ) : (
                <p className="text-gray-500">No groups created by this user.</p>
              )}
            </div>
            <div className="w-1/2">
              <strong className="text-gray-700">Group I am in:</strong>
              {GroupkittyCount?.joinedGroupsCount ? (
                  <p k className="text-gray-500 mt-1">{GroupkittyCount?.joinedGroupsCount}</p>
              ) : (
                <p className="text-gray-500">No groups attended by this user.</p>
              )}
            </div>
              <div className="w-1/2">
                <strong className="text-gray-700">Kitty Created By Me:</strong>
                {GroupkittyCount?.kittyCount ? (
                    <p  className="text-gray-500 mt-1">{GroupkittyCount?.kittyCount}</p>
                ) : (
                  <p className="text-gray-500">No kitty created by this user.</p>
                )}
              </div>
            <div className="w-1/2">
              <strong className="text-gray-700">Social Links:</strong>
              {user.sociallinks && user.sociallinks.length > 0 ? (
                user.sociallinks.map((link, idx) => (
                  <div key={idx} className="mt-1 text-gray-500">
                    {link.instaurl && (
                      <p>Instagram: <a href={link.instaurl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{link.instaurl}</a></p>
                    )}
                    {link.Linkedinurl && (
                      <p>LinkedIn: <a href={link.Linkedinurl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{link.Linkedinurl}</a></p>
                    )}
                    {link.Websiteurl && (
                      <p>Website: <a href={link.Websiteurl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{link.Websiteurl}</a></p>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No social links available</p>
              )}
            </div>
          </div>
        ) : (
          <p className="text-gray-600">Loading...</p>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-gray-100 p-3">
        <Button variant="secondary" onClick={handleClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
          Close
        </Button>
      </Modal.Footer>
    </div>
  </Modal>
  
  
  
  
  
  );
};

export default UserInformation;
