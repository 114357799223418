import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { SketchPicker } from "react-color";

const AddColorForm = () => {
  const { id } = useParams();
  const [colorHexCode, setColorHexCode] = useState("#000000"); 
  const [formData, setFormData] = useState({
    name: "",
  });
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchColorData(id);
    }
  }, [id]);

  const fetchColorData = async (colorId) => {
    try {
      const response = await axios.get(`${apiUrl}/getColorById/${colorId}`);
      const colorData = response.data;
      setFormData({
        name: colorData.name,
      });
      setColorHexCode(colorData.name); // Assuming the backend sends a `hexCode`
    } catch (error) {
      console.error("Failed to fetch color data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataToSend = {
        name: formData.name,
        hexCode: colorHexCode, // Sending the selected color hex code
      };

      if (id) {
        await axios.put(`${apiUrl}/updateColor/${id}`, dataToSend);
        toast.success("Color Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/addColor`, dataToSend);
        toast.success("Color Added Successfully", {
          autoClose: 2000,
        });
      }

      setTimeout(() => {
        navigate("/kitty-color");
      }, 1000);
    } catch (error) {
      console.error("Failed to submit color data:", error);
    }
  };

  const handleClose = () => {
    navigate("/kitty-color");
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card p-0">
            <div className="card-body w-full p-0 pb-3">
              <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
                <h2 className="card-title mb-0">{id ? "Update Color" : "Add Color"}</h2>
                <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
              </div>
              <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
                <div className="mb-3 col-md-12 p-2">
                  <div>
                    <SketchPicker
                      color={colorHexCode}
                      onChange={(color) => setColorHexCode(color.hex)} // Set the hex value
                    />
                  </div>
                  <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Color Hex Code
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="name"
                    disabled
                    placeholder="Color Hex Code"
                    value={colorHexCode}
                    required
                  />
                </div>

                <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
                  {id ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddColorForm;
