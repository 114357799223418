import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "../components/Card";
import BarChart from "../components/BarChart";
import DonutChart from "../components/DonutChart";
import { FaUsers, FaUsersCog, FaBuilding, FaPiggyBank } from "react-icons/fa";
import "./dashboard.css";
import messages from "../messages.json";
import { MdGroups2 } from "react-icons/md";
import { FaHotel } from "react-icons/fa";
import { MdCardMembership } from "react-icons/md"
import { FaUser } from "react-icons/fa";
import roleService from '../services/roleService';  

const Dashboard = () => {
  const [counts, setCounts] = useState({
    user: 0,
    group: 0,
    venue: 0,
    kitty: 0,
  });
  const [barChartData, setBarChartData] = useState([]);
  const [circleChartData, setCircleChartData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        let rolesData = await roleService.fetchRoles();  // Call the service method
        console.log(rolesData, 'ddddddddd');
        localStorage.setItem('roleData',JSON.stringify(rolesData))

        rolesData = rolesData.filter((item) => item?.userId?.email !== 'admin@gmail.com');
        
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    
    fetchRoles();
  }, []); 

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/getUserKittyVenueGroupCount`
        );
        const data = response.data;
        setCounts({
          user: data.user,
          group: data.group,
          venue: data.venue,
          kitty: data.kitty,
        });
        // Prepare chart data
        setBarChartData([
          { name: "Kitty Users", data: [data.user] },
          { name: "Kitty Groups", data: [data.group] },
          { name: "Kitty Venues", data: [data.venue] },
          { name: "Kitty Count", data: [data.kitty] },
        ]);

        setCircleChartData([data.user, data.group, data.venue, data.kitty]);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchCounts();
  }, [apiUrl]);




  return (
   <div className="container">
     <div className="mx-auto max-w-screen-2xl py-6">
      {/* Header */}
      <h1 className="text-3xl font-semibold text-gray-900 mb-8 font-nunito">
        Admin Dashboard
      </h1>

      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
          <FaHotel />
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">
                {counts.venue}
              </h4>
              <span class="text-sm font-medium">Total Venues</span>
            </div>
            <span class="flex items-center gap-1 text-sm font-medium text-meta-3 undefined ">
              0.43%
              <svg
                class="fill-meta-3"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                  fill=""
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
        
          <MdCardMembership />
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">
                {counts.kitty}
              </h4>
              <span class="text-sm font-medium">Total Kitty</span>
            </div>
            <span class="flex items-center gap-1 text-sm font-medium text-meta-3 undefined ">
              4.35%
              <svg
                class="fill-meta-3"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                  fill=""
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
          <MdGroups2/>
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">
                {counts.group}
              </h4>
              <span class="text-sm font-medium">Total Groups</span>
            </div>
            <span class="flex items-center gap-1 text-sm font-medium text-meta-3 undefined ">
              2.59%
              <svg
                class="fill-meta-3"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                  fill=""
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
          <FaUser />
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">{counts.user}</h4>
              <span class="text-sm font-medium">Total Users</span>
            </div>
            <span class="flex items-center gap-1 text-sm font-medium undefined text-meta-5 ">
              0.95%
              <svg
                class="fill-meta-5"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                  fill=""
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="mt-10 grid grid-cols-1 gap-4 lg:grid-cols-2">
        {/* Bar Chart */}
        <div className="bg-white p-6 shadow-md border border-gray-200 ">
          <h2 className="text-xl font-medium text-gray-900 mb-6">
            Kitty Statistics
          </h2>
          <BarChart data={barChartData} />
        </div>

        {/* Donut Chart */}
        <div className="bg-white p-6 shadow-md border border-gray-200 ">
          <h2 className="text-xl font-medium text-gray-900 mb-4">
            Participation Breakdown
          </h2>
          <DonutChart
            data={circleChartData}
            labels={["Users", "Groups", "Venues", "Kitty"]}
          />
        </div>
      </div>
    </div>

   </div>
  );
};

export default Dashboard;
