import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const DonutChart = ({ data, labels }) => (
  <Chart
    options={{
      chart: { type: 'donut' },
      labels: labels,
    }}
    series={data}
    type="donut"
    height="250"
  />
);

DonutChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DonutChart;
