import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";

const AddActivityForm = () => {
  const { id } = useParams(); // Extract user ID from URL params
  const [formData, setFormData] = useState({
    name: "",
    description: ""
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate()


  useEffect(() => {
    if (id) {
      fetchinterestData(id);
    }
  }, [id]);

  const fetchinterestData = async (activityId) => {
    try {
      const response = await axios.get(`${apiUrl}/getActivityById/${activityId}`);
      const interestData = response?.data;
      setFormData({
        name: interestData?.name,
        description: interestData?.description
      
      });
    } catch (error) {
      console.error("Failed to fetch interest data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (id) {
        await axios.put(`${apiUrl}/updateActivity/${id}`, formData);
        toast.success("Activity Updated Successfully", {
          autoClose: 2000,
        });
        
    } else {
        await axios.post(`${apiUrl}/addActivity`, formData);
        toast.success("Activity Added Successfully", {
          autoClose: 2000,
        });
        
    }
    setFormData({
        name: "",
        description: ""
    });
    setTimeout(() => {
        
        navigate('/kitty-activity');
    }, 1000);

    } catch (error) {
      console.error("Failed to submit interest data:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
   
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    
  };


  const handleClose = () => {
    navigate('/kitty-activity')
  }

  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0">
        <div className="c card-body w-full p-0 pb-3">
        <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">

          <h2 className="card-title mb-0">{id ? "Update Activity" : "Add Activity"}</h2>
          <FaTimes
              className="text-danger cursor-pointer"
              onClick={handleClose}
            />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Activity Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter Activity name"
                value={formData?.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="description" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Enter Description"
                value={formData?.description}
                onChange={handleChange}
                required
              />
            </div>
         
         
            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      </div>
     </div>
    </div>
  );
};

export default AddActivityForm;
