import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import { FaTimes } from "react-icons/fa";


const Banner = () => {
  const [formData, setFormData] = useState({
    image: null,
    video: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null); // Fix variable name
  const [videoUrl, setVideoUrl] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedImageUrl = imageUrl;
    let uploadedVideoUrl = videoUrl; // Fix variable name

    // Upload Image to S3
    if (formData.image) {
      try {
        const file = formData.image;
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        const data = await s3.upload(params).promise();
        uploadedImageUrl = data.Location;
        setImageUrl(uploadedImageUrl); // Update image URL state
      } catch (err) {
        console.error("Error uploading image:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }

    // Upload Video to S3
    if (formData.video) {
      try {
        const file = formData.video; // Fix variable name
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        const data = await s3.upload(params).promise();
        uploadedVideoUrl = data.Location;
        setVideoUrl(uploadedVideoUrl); // Update video URL state
      } catch (err) {
        console.error("Error uploading video:", err);
        toast.error("Failed to upload video", { autoClose: 2000 });
        return;
      }
    }

    // Submit form data
    const form = new FormData();
    form.append("image", uploadedImageUrl); // Append uploaded image URL
    form.append("video", uploadedVideoUrl); // Append uploaded video URL

    try {
      await axios.post(`${apiUrl}/addBanner`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Banner added successfully!", { autoClose: 2000 });
      setFormData({ image: null, video: null });
      setImagePreview(null); // Clear image preview
      setVideoPreview(null); // Clear video preview
    } catch (error) {
      console.error("Error adding banner:", error);
      toast.error("Error adding banner.");
    }
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: files[0],
    }));

    if (id === "image" && files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(files[0]);
    }

    if (id === "video" && files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result); // Set video preview
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleImageRemove = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: null,
    }));
    setImagePreview(null); // Remove image preview
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
        <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
        <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
        <h2 className="card-title mb-0">Add Banner</h2>
        <FaTimes className="text-danger cursor-pointer" />
         
          </div>
          
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="image" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept=".jpg,.jpeg"
                onChange={handleFileChange}
              />
              {imagePreview && (
                <div className="mb-3 col-md-6 p-2">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                  <FaTimes className="image-remove-icon" onClick={handleImageRemove} />
                </div>
              )}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="video" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Video</label>
              <input
                type="file"
                className="form-control"
                id="video"
                accept="video/*"
                onChange={handleFileChange}
              />
              {videoPreview && (
                <div className="mt-3">
                  <video controls style={{ maxWidth: "100%", maxHeight: "300px" }}>
                    <source src={videoPreview} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>
            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">Submit</button>
          </form>
        </div>
      </div>

        </div>
      </div>
    </div>
  );
};

export default Banner;
