import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import TemplateInformation from "../forms/templateInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Template', 'View');
  const showAdd = hasPermission('Template', 'Add');
  const showEdit = hasPermission('Template', 'Update');
  const showDelete = hasPermission('Template', 'Delete');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllTemplates`);
        const fetchedTemplates = response.data.data;
        console.log(fetchedTemplates);

        setTemplates(fetchedTemplates);
        setFilteredTemplates(fetchedTemplates);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterTemplates();
  }, [searchInput, templates, selectedStatuses]);

  const filterTemplates = () => {
    let filtered = templates;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (template) =>
          template.name &&
          template.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((template) =>
        selectedStatuses.includes(template.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredTemplates(filtered);
  };

  const handleShowDeleteModal = (templateId) => {
    setTemplateToDelete(templateId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setTemplateToDelete(null);
  };

  const handleDelete = async () => {
    if (templateToDelete) {
      try {
        console.log(apiUrl);
        await axios.delete(`${apiUrl}/deleteTemplateById/${templateToDelete}`);
        console.log(templateToDelete, "check delete ");
        setTemplates(
          templates.filter((template) => template._id !== templateToDelete)
        );
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setTemplateToDelete(null);
      }
    }
  };

  const handleUpdate = (templateId) => {
    navigate(`/template/update/${templateId}`);
  };

  const handleStatusToggle = async (templateId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateTemplateById/${templateId}`, {
        isActive: updatedStatus,
      });
  
      setTemplates(
        templates.map((template) =>
          template._id === templateId
            ? { ...template, isActive: updatedStatus }
            : template
        )
      );
      toast.success(
        `Template status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };
  const handleView = async (templateId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/getTemplateById/${templateId}`
      );
      setSelectedTemplate(response.data.data);
      console.log("for test", response.data.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTemplate(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    { header: "Name", accessor: "name" },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Template Information</h2>
        </div>

        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 "
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (

          <div className="col-auto">
          <Link to="/template/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Template
          </Link>
        </div>
        )}
      </div>

      <CustomTable
        tableData={filteredTemplates}
        columns={columns}
        handleStatusToggle={(templateId, currentStatus) => handleStatusToggle(templateId, currentStatus)}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <TemplateInformation
        showModal={showModal}
        closeModal={handleCloseModal}
        template={selectedTemplate}
      />

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        closeModal={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Templates;
