import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/login";
import Register from "../pages/register";
import PublicRoutes from "./publicRoutes";
import ProtectedRoutes from "./privateRoutes";
import Layout from "../components/Layout";
import AddUserForm from "../forms/AddUserForm";
import AddGroupForm from "../forms/AddGroupForm";
import AddVenueForm from "../forms/AddVenueForm";
import AddInterestForm from "../forms/AddinterestsForm";
import Venue from "../pages/Venue";
import Profile from "../pages/Profile";
import Interest from "../pages/Interest";
import User from "../pages/User";
import Group from "../pages/Group";
import Dashboard from "../pages/dashboard";
import AddKittyForm from "../forms/AddKittyForm";
import Activity from "../pages/Activity";
import AddActivityForm from "../forms/AddActivityForm";
import Themes from "../pages/Themes";
import AddThemesForm from "../forms/AddThemesForm";
import RoleManagement from "../pages/role_management";
import Banner from "../forms/AddBanner";
import Frequency from "../pages/groupFrequency";

import GroupInterest from "../pages/groupInterest";
import AddGroupInterest from "../forms/AddGroupInterest";
import AddGroupFrequency from "../forms/AddGroupFrequency";
import Post from "../pages/post";
import Template from "../pages/template";
import Color from "../pages/color";
import { usePermissions } from "../hooks/PermissionsContext";

import AddTemplateForm from "../forms/AddTemplateform";
import AddColorForm from "../forms/AddColorForm";
import Category from "../pages/venueCategory";
import AddVenueCategoryForm from "../forms/AddvenueCategory";
import AddPostForm from "../forms/AddPost";
import City from "../pages/city";
import AddCityForm from "../forms/Addcityform";
import Booking from "../pages/Booking";
import AddBookingForm from "../forms/AddBookingform";
import TermsAndServices from "../pages/Terms&services";
import AddTermsAndServicesForm from "../forms/Addterms&services";
import Feedback from "react-bootstrap/esm/Feedback";
import Support from "../pages/Feedback";
import AddFeedbackForm from "../forms/Addfeedback";
import WishList from "../pages/Wishlist";
import AddWishlistForm from "../forms/AddWishlistform";
import Address from "../pages/Address";
import AddAddressForm from "../forms/AddAddressform";
import WalletTransaction from "../pages/WalletTransaction";
import AddWalletTransactionForm from "../forms/AddWalletTransactionForm";
import AddRoleForm from "../forms/AddRolesForm";



const Info = lazy(() => import(/* webpackChunkName: "KittyInfo" */ "../pages/Info"));

const AppRouter = () => {
  const { hasPermission } = usePermissions();

  // const showUserRoute = hasPermission('Users', 'View'); // Check if the user has permission to view "Kitty Users"
  // const showGroupRoute = hasPermission('Groups', 'View'); // Check if the user has permission to view "Kitty Groups"
  return (

  <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} /> 
      <Route
        path="/login"
        element={
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoutes>
            <Register />
          </PublicRoutes>
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoutes>
            <Layout>
              <Dashboard />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-users"
        element={
          <ProtectedRoutes>
            <Layout>
              <User/>
            </Layout>
          </ProtectedRoutes>
        }
      />
{/* {showGroupRoute && ( */}
  <>
    <Route
      path="/kitty-groups"
      element={
        <ProtectedRoutes>
          <Layout>
            <Group />
          </Layout>
        </ProtectedRoutes>
      }
    />

    <Route
      path="/kitty-groups/add"
      element={
        <ProtectedRoutes>
          <Layout>
            <AddGroupForm />
          </Layout>
        </ProtectedRoutes>
      }
    />

   <Route
        path="/kitty-group/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddGroupForm />
            </Layout>
          </ProtectedRoutes>
        }
      />

  </>
{/* )
} */}

     
      <Route
        path="/kitty-venue"
        element={
          <ProtectedRoutes>
            <Layout>
              <Venue />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-venues/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddVenueForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
    
      <Route
        path="/kitty-venues/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddVenueForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-users/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddUserForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-users/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddUserForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
    

      <Route
        path="/kitty-info"
        element={
          <ProtectedRoutes>
            <Layout>
              <Info />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-info/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddKittyForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-info/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddKittyForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoutes>
            <Layout>
              <Profile />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-activity"
        element={
          <ProtectedRoutes>
            <Layout>
              <Activity/>
            </Layout>
          </ProtectedRoutes>
        }
      />
    
    <Route
        path="/kitty-activity/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddActivityForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
    <Route
        path="/kitty-update/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddActivityForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-theme"
        element={
          <ProtectedRoutes>
            <Layout>
              <Themes/>
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-theme/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddThemesForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-theme/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddThemesForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-role-management"
        element={
          <ProtectedRoutes>
            <Layout>
              <RoleManagement />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-roles/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddRoleForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-role-management/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddRoleForm />
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-banner"
        element={
          <ProtectedRoutes>
            <Layout>
              <Banner />
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/group-frequency"
        element={
          <ProtectedRoutes>
            <Layout>
              <Frequency />
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/group-frequency/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddGroupFrequency/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/group-frequency/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddGroupFrequency/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/group-interest"
        element={
          <ProtectedRoutes>
            <Layout>
              <GroupInterest/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/group-interest/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddGroupInterest/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/group-interest/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddGroupInterest/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-post"
        element={
          <ProtectedRoutes>
            <Layout>
              <Post/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-post/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddPostForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-post/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddPostForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-interest"
        element={
          <ProtectedRoutes>
            <Layout>
              <Interest/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-interest/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddInterestForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-interest/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddInterestForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-template"
        element={
          <ProtectedRoutes>
            <Layout>
              <Template/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/template/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddTemplateForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/template/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddTemplateForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
     
     <Route
        path="/kitty-color"
        element={
          <ProtectedRoutes>
            <Layout>
              <Color/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-color/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddColorForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-color/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddColorForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-venue-category"
        element={
          <ProtectedRoutes>
            <Layout>
              <Category/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-category/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddVenueCategoryForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-category/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddVenueCategoryForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-city"
        element={
          <ProtectedRoutes>
            <Layout>
              <City/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-city/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddCityForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-city/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddCityForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/kitty-booking"
        element={
          <ProtectedRoutes>
            <Layout>
              <Booking/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-Bookings/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddBookingForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-Bookings/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddBookingForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-terms&Services"
        element={
          <ProtectedRoutes>
            <Layout>
              <TermsAndServices/>
            </Layout>
          </ProtectedRoutes>
        }
      />

      <Route
        path="/kitty-TermsAndService/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddTermsAndServicesForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-TermsAndService/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddTermsAndServicesForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-feedback"
        element={
          <ProtectedRoutes>
            <Layout>
              <Support/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-Feedback/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddFeedbackForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-Feedback/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddFeedbackForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-wishlist"
        element={
          <ProtectedRoutes>
            <Layout>
              <WishList/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-wishlist/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddWishlistForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-wishlist/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddWishlistForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-address"
        element={
          <ProtectedRoutes>
            <Layout>
              <Address/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-Address/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddAddressForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-Address/update/:id"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddAddressForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
       <Route
        path="/kitty-walletTransactionHistory"
        element={
          <ProtectedRoutes>
            <Layout>
              <WalletTransaction/>
            </Layout>
          </ProtectedRoutes>
        }
      />
         <Route
        path="/kitty-WalletTransaction/add"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddWalletTransactionForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
        <Route
        path="/kitty-WalletTransaction/update/:id/:objId"
        element={
          <ProtectedRoutes>
            <Layout>
              <AddWalletTransactionForm/>
            </Layout>
          </ProtectedRoutes>
        }
      />
      </Routes>
  </Router>
)

}
export default AppRouter;
